import Ripple from 'material-ripple-effects';
import { Chip } from "@material-tailwind/react";

function TodayArticle({ title, cover, date, timeToRead, category, onClick }) {
	const ripple = new Ripple();
	return (
		<button type="button" onClick={onClick} onMouseUp={(e) => ripple.create(e, 'light')} className="text-start grid gap-x-0 gap-y-3">
			<img src={cover} className="object-cover w-full rounded-3xl" style={{'height': '180px'}} alt="Cover" />
			<div className="flex items-start justify-start">
				<Chip variant="ghost" className="rounded-full" value={category} />
			</div>
			<h4 className="font-bold text-lg dark:text-white">{title}</h4>
			<div className="inline-flex gap-x-3">
				<span className="text-xs text-gray-500 dark:text-gray-400">{date}</span>
				<span className="text-xs dark:text-white">&bull;</span>
				<span className="text-xs text-gray-500 dark:text-gray-400">{timeToRead} read</span>
			</div>
		</button>
	);
}

export default TodayArticle;