import React from 'react';

import {
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";

import ReactDOM from 'react-dom/client';
import LoginPage from './Pages/LoginPage.js';
import SignupPage from './Pages/SignupPage.js'
import HomePage from './Pages/HomePage.js';
import ComposePage from './Pages/ComposePage.js';
import AccountPage from './Pages/AccountPage.js';
import ErrorPage from './Pages/ErrorPage.js';
import { initializeApp } from "firebase/app";

import appConfig from './Server/Config.js';
import Messaging from './Server/Messaging.js';
import './index.css';

import { getAnalytics } from "firebase/analytics";

if ('serviceWorker' in navigator) {
	window.addEventListener('load', function () {
		navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		}, function (err) {
			console.error('ServiceWorker registration failed: ', err);
		});
	});
}

const app = initializeApp(appConfig);
getAnalytics(app);

Messaging.getWebToken();

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomePage />,
		errorElement: <ErrorPage />
	},
	{
		path: '/auth/login',
		element: <LoginPage />
	},
	{
		path: '/auth/signup',
		element: <SignupPage />
	},
	{
		path: '/compose/new',
		element: <ComposePage />
	},
	{
		path: '/account/settings',
		element: <AccountPage />
	}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
