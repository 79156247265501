import PageLayout from './../Component/PageLayout.js';
import NavBar from './../Component/Widget/NavBar.js';
import ContentLayout from './../Component/ContentLayout.js';
import CenterLayout from './../Component/CenterLayout.js';

import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { IconButton, Typography } from "@material-tailwind/react";

function AccountPage() {
	document.title = 'Account - IAMREX';
	return (
		<PageLayout>
			<CenterLayout>
				<div className="w-full h-full sm:w-96">
					<NavBar title="Account" />
					<ContentLayout>
						<div className="mt-20 w-full">
							<div className="normal-case flex items-start justify-start place-items-center w-full px-0 py-2">
								<div className="grid text-start my-auto w-full">
									<Typography variant="h6">Profile Picture</Typography>
									<Typography variant="small">Set or update a profile picture.</Typography>
								</div>
								<IconButton variant="text" className="ms-3 rounded-full my-auto">
									<ArrowForwardSharpIcon fontSize="small" />
								</IconButton>
							</div>

							<div className="normal-case flex items-start justify-start place-items-center w-full px-0 py-2">
								<div className="grid text-start my-auto w-full">
									<Typography variant="h6">Display Name</Typography>
									<Typography variant="small">Set or update your profile name.</Typography>
								</div>
								<IconButton variant="text" className="ms-3 rounded-full my-auto">
									<ArrowForwardSharpIcon fontSize="small" />
								</IconButton>
							</div>

							<div className="normal-case flex items-start justify-start place-items-center w-full px-0 py-2">
								<div className="grid text-start my-auto w-full">
									<Typography variant="h6">Email address</Typography>
									<Typography variant="small">Change or update your email.</Typography>
								</div>
								<IconButton variant="text" className="ms-3 rounded-full my-auto">
									<ArrowForwardSharpIcon fontSize="small" />
								</IconButton>
							</div>

							<div className="normal-case flex items-start justify-start place-items-center w-full px-0 py-2">
								<div className="grid text-start my-auto w-full">
									<Typography variant="h6">Password</Typography>
									<Typography variant="small">Change or update your password.</Typography>
								</div>
								<IconButton variant="text" className="ms-3 rounded-full my-auto">
									<ArrowForwardSharpIcon fontSize="small" />
								</IconButton>
							</div>

							<div className="normal-case flex items-start justify-start place-items-center w-full px-0 py-2">
								<div className="grid text-start my-auto w-full">
									<Typography variant="h6">Membership</Typography>
									<Typography variant="small">Change or update membership.</Typography>
								</div>
								<IconButton variant="text" className="ms-3 rounded-full my-auto">
									<ArrowForwardSharpIcon fontSize="small" />
								</IconButton>
							</div>
						</div>
					</ContentLayout>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default AccountPage;