import React from 'react';
import 'flowbite';

class TodayArticleLoader extends React.Component {
	render() {
		return (
			<div className="space-y-2 animate-pulse text-start grid gap-x-0 gap-y-3">
				<div className="w-full bg-gray-300 rounded-3xl" style={{'height': '180px'}}></div>
				<div>
					<div className="h-8 w-24 bg-gray-300 rounded font-semibold px-4 py-2 bg-gray-300 dark:bg-gray-800 rounded-full"></div>
				</div>
				<div className="font-bold h-3 bg-gray-300 rounded w-full"></div>
				<div className="font-bold h-3 bg-gray-300 rounded w-full"></div>
				<div className="inline-flex gap-x-3">
					<span className="bg-gray-300 rounded w-32 h-2"></span>
					<span className="bg-gray-300 rounded w-5 h-2"></span>
					<span className="bg-gray-300 rounded w-32 h-2"></span>
				</div>
			</div>
		);
	}
}

export default TodayArticleLoader;