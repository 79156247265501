import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Option, Spinner } from "@material-tailwind/react";
import { $generateHtmlFromNodes } from '@lexical/html';
import { Button, Input, Typography } from "@material-tailwind/react";
import { useFilePicker } from 'use-file-picker';
import {
	FileAmountLimitValidator,
	FileTypeValidator,
	FileSizeValidator
} from 'use-file-picker/validators';

import PageLayout from './../Component/PageLayout.js';
import NavBar from './../Component/Widget/NavBar.js';
import AlertDialog from './../Component/Modal/AlertDialog.js';
import ContentLayout from './../Component/ContentLayout.js';
import CenterLayout from './../Component/CenterLayout.js';
import TextEditor from './../Component/Widget/TextEditor.js';
import Tool from './../Component/Utility/Tool.js';
import appConfig from './../Server/Config.js';

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage, getDownloadURL, ref, uploadString } from "firebase/storage";

const app = initializeApp(appConfig);

const auth = getAuth();
const database = getFirestore(app);
const storage = getStorage();

function ComposePage() {
	document.title = 'New Post - IAMREX';
	const [content, setContent] = useState('');
	const [file, setFile] = useState(null);
	const [category, setCategory] = useState('astronomy');
	const [error, setError] = useState(null);
	const [title, setTitle] = useState('');
	const [status, setStatus] = useState('typing');

	const navigate = useNavigate();

	function onChange(editorState, editor) {
		editorState.read(() => {
			const htmlOutput = $generateHtmlFromNodes(editor);
			setContent(htmlOutput);
		});
	}

	const { openFilePicker } = useFilePicker({
		readAs: 'DataURL',
		accept: 'image/*',
		multiple: false,
		validators: [
			new FileAmountLimitValidator({ min: 1, max: 1 }),
			new FileTypeValidator(['jpg', 'jpeg']),
			new FileSizeValidator({ maxFileSize: 5 * 1024 * 1024 /* 5MB */ })
		],
		onFilesSelected: ({ plainFiles, filesContent, errors }) => {
			setFile(filesContent);
		},
		onFilesRejected: ({ errors }) => {
			setError({ message: 'File picker error.' });
		},
		onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
			setFile(filesContent);
		},
	});

	const handlePublishClick = () => {
		if (!file) {
			setError({ message: 'Select a photo.' });
		} else {
			setStatus('uploading');
			const id = Tool.generateRandomId(12);
			const metadata = {
				contentType: 'image/jpeg',
				customMetadata: {
					resourceOwnerId: auth.currentUser.uid,
					associatedPostId: id
				}
			}
			const photoRef = ref(storage, `contents/images/${Date.now() + '/' + file[0].name}`);
			uploadString(photoRef, file[0].content, 'data_url', metadata)
				.then((snapshot) => {
					getDownloadURL(photoRef)
						.then((url) => {
							setDoc(doc(database, 'posts', id), {
								id: id,
								cover: url,
								title: title,
								content: content,
								category: category,
								uid: auth.currentUser.uid,
								status: 'public',
								created_at: Date.now()
							})
								.then(() => navigate('/?upload=success'))
								.catch((error) => setError(error));
						})
						.catch((error) => {
							setStatus('typing');
							setError(error);
						});
				}).catch((error) => setError(error));
		}
	}

	const categories = [{
		id: 'astronomy',
		name: 'Astronomy'
	}, {
		id: 'beauty',
		name: 'Beauty'
	}, {
		id: 'business',
		name: 'Business'
	}, {
		id: 'communication',
		name: 'Communication'
	}, {
		id: 'diy-and-crafts',
		name: 'DIY & Crafts'
	}, {
		id: 'education',
		name: 'Education'
	}, {
		id: 'entertainment',
		name: 'Entertainment'
	}, {
		id: 'fashion',
		name: 'Fashion'
	}, {
		id: 'finance',
		name: 'Finance'
	}, {
		id: 'food',
		name: 'Food'
	}, {
		id: 'gaming',
		name: 'Gaming'
	}, {
		id: 'health-and-fitness',
		name: 'Health & Fitness'
	}, {
		id: 'home-and-garden',
		name: 'Home & Garden'
	}, {
		id: 'lifestyle',
		name: 'Lifestyle'
	}, {
		id: 'music',
		name: 'Music'
	}, {
		id: 'parenting',
		name: 'Parenting'
	}, {
		id: 'personal-development',
		name: 'Personal Development'
	}, {
		id: 'photography',
		name: 'Photography'
	}, {
		id: 'relationships',
		name: 'Relationships'
	}, {
		id: 'technology',
		name: 'Technology'
	}, {
		id: 'travel',
		name: 'Travel'
	}];

	return (
		<PageLayout>
			<CenterLayout>
				{error !== null && (<AlertDialog show={true} title="Error" message={error.message} onDismiss={() => setError(null)} />)}
				<div className="w-full h-full sm:w-96">
					<NavBar title="New Post" action={(<Button onClick={handlePublishClick} variant="text" size="sm">{status === 'uploading' ? (<Spinner className="h-4 w-4 text-gray-200" size="sm" />) : 'PUBLISH'}</Button>)} />
					<ContentLayout className="grid gap-y-2 pb-4 pt-20">
						<TextEditor onChange={onChange} />
						<Typography variant="h6">Post</Typography>
						<div className="mt-5 mb-5 w-full">
							<Input label="Title" size="lg" onChange={(e) => setTitle(e.target.value)} />
						</div>
						<Typography variant="h6">Cover</Typography>
						<Button variant="text" size="sm" onClick={() => openFilePicker()} className="p-0 border border-gray-300 rounded-lg text-center items-center justify-center place-items-center h-52 mt-5 mb-5" fullWidth>
							{!file ?
								(<div>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1}
										stroke="currentColor"
										className="h-10 w-10 mx-auto">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
										/>
									</svg>
									Upload Photo
								</div>) :
								(<img alt={file[0].name} className="w-full h-full object-cover rounded-lg" src={file[0].content} />)
							}
						</Button>
						<Typography variant="h6">Category</Typography>
						<div className="w-full mt-5 mb-5">
							<Select
								value={category}
								variant="outlined"
								label="Choose Category"
								onChange={(val) => setCategory(val)}
								animate={{
									mount: { y: 0 },
									unmount: { y: 25 },
								}} size="lg">
								{categories.map((category) =>
									(<Option value={category.id}>{category.name}</Option>))}
							</Select>
						</div>
					</ContentLayout>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default ComposePage;