import React from 'react';
import { Avatar } from "@material-tailwind/react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import appConfig from './../Server/Config.js';

class ProfilePhoto extends React.Component {
	render() {
		initializeApp(appConfig);
		const auth = getAuth();
		const user = auth.currentUser;

		if (user) {
			return (
				<Avatar className="border border-gray-300" src={user.photoURL === null ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png' : user.photoURL} size="sm" alt="avatar" />
			);
		} else {
			return (
				<Avatar className="border border-gray-300" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" size="sm" alt="avatar" />
			);
		}
	}
}

export default ProfilePhoto;