class Tool {
	static generateRandomId(length) {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charsLength = chars.length;
		let randomId = '';

		for (let i = 0; i < length; i++) {
			randomId += chars.charAt(Math.floor(Math.random() * charsLength));
		}

		return randomId;
	}

	static getCategoryById(id) {
		switch (id) {
			case 'astronomy':
				return 'Astronomy';

			case 'beauty':
				return 'Beauty';

			case 'business':
				return 'Business';

			case 'communication':
				return 'Communication';

			case 'diy-and-crafts':
				return 'DIY & Crafts';

			case 'education':
				return 'Education';

			case 'entertainment':
				return 'Entertainment';

			case 'fashion':
				return 'Fashion';

			case 'finance':
				return 'Finance';

			case 'food':
				return 'Food';

			case 'gaming':
				return 'Gaming';

			case 'health-and-fitness':
				return 'Health & Fitness';

			case 'home-and-garden':
				return 'Home & Garden';

			case 'lifestyle':
				return 'Lifestyle';

			case 'music':
				return 'Music';

			case 'parenting':
				return 'Parenting';

			case 'personal-development':
				return 'Personal Development';

			case 'photography':
				return 'Photography';

			case 'relationships':
				return 'Relationahips';

			case 'technology':
				return 'Technology';

			case 'travel':
				return 'Travel';

			default:
				return 'General';
		}
	}

	static formatDate(timestamp) {
		const date = new Date(timestamp);
		const options = { month: 'long', day: 'numeric', year: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	}

	static truncate(str, maxlength) {
		if (str.length > maxlength) {
			return str.slice(0, maxlength - 1) + "…";
		} else {
			return str;
		}
	}
}

export default Tool;