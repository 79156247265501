import React from 'react';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';

class SearchField extends React.Component {
	render() {
		return (
			<div className="mt-3 mb-3 flex items-start justify-items-start border border-gray-300 px-3 py-1 rounded-full">
				<input type="text" class="text-black dark:text-white border-0 focus:outline-none focus:ring-0 w-full" placeholder="Seach for a topic" />
				<button type="button" class="bg-white rounded-full dark:bg-gray-900 w-6 h-4">
					<SearchSharpIcon className="mt-2 dark:text-white hover:text-gray-500 dark:hover:text-gray-400"></SearchSharpIcon>
				</button>
			</div>
		);
	}
}

export default SearchField;