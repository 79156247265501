import { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Input } from "@material-tailwind/react";

import Button from './../Component/Widget/Button.js';
import ProgressLoader from './../Component/Widget/ProgressLoader.js';
import OutlinedButton from './../Component/Widget/OutlinedButton.js';
import CenterLayout from './../Component/CenterLayout.js';
import PageLayout from './../Component/PageLayout.js';
import logo from './../logo.png'
import 'animate.css';

import appConfig from './../Server/Config.js';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

function LoginPage() {
	document.title = 'Login - IAMREX';

	const [status, setStatus] = useState('typing');
	const [error, setError] = useState(null);
	const [emailValue, setEmailValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');


	initializeApp(appConfig);
	const auth = getAuth();

	const onTextEmailChanged = (event) => {
		setEmailValue(event.target.value);
	}

	const onTextPasswordChanged = (event) => {
		setPasswordValue(event.target.value);
	}

	const handleLoginClick = (event) => {
		event.preventDefault();
		doLogin()
			.then((response) => {
				setStatus('success');
			})
			.catch((err) => {
				setError(err);
			})
	};

	function doLogin() {
		return new Promise((resolve, reject) => {
			if (emailValue.length < 6) {
				reject('Email address should be higher than 6 characters.')
			} else if (passwordValue.length < 6) {
				reject('Password should be higher than 6 characters.')
			} else {
				setStatus('submitting');
				signInWithEmailAndPassword(auth, emailValue, passwordValue)
					.then((result) => {
						resolve(result);
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						switch (errorCode) {
							case 'auth/wrong-password':
								reject('Incorrect password.');
								break;

							case 'auth/network-request-failed':
								reject('Network error.');
								break;

							case 'auth/user-not-found':
								reject('Account does\'t exist.');
								break;

							case 'auth/invalid-credential':
								reject('Account does\'t exist.');
								break;

							default:
								reject('An error occured. Please try again.');
								break;
						}
						setStatus('error');
						console.log(errorMessage);
					});
			}
		});
	}

	onAuthStateChanged(auth, (user) => {
		if (user) {
			setStatus('success');
		}
	});

	return (
		<PageLayout>
			<CenterLayout>
				{
					status === 'success' && (<Navigate to="/" replace={true} />)
				}
				<div className="w-full h-full pt-14 sm:w-96">
					<div className="flex items-center justify-center mb-5">
						<img className="h-14" src={logo} alt="Logo" />
					</div>
					<div className="px-4 mb-5 sm:px-0">
						<Input onChange={onTextEmailChanged} type="email" label="Email address" size="lg" />
					</div>
					<div className="px-4 mb-3 sm:px-0">
						<Input onChange={onTextPasswordChanged} type="password" label="Password" size="lg" />
					</div>
					{error !== null &&
						<div className="px-4 mt-2 sm:px-0">
							<p className="text-xs text-red-600">
								{error}
							</p>
						</div>
					}
					<div className="grid gap-y-5 px-4 mt-5 mb-3 sm:px-0">
						<Button onClick={handleLoginClick} label={status === 'submitting' ? (<ProgressLoader />) : 'LOGIN'}></Button>
						<Link to="/auth/signup">
							<OutlinedButton label="SIGN UP"></OutlinedButton>
						</Link>
					</div>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default LoginPage;