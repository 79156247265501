const appConfig = {
	apiKey: "AIzaSyCSnvS7BPnbuFcFCYSMlYpr7uomTcMWDOY",
	authDomain: "iamrex.eu.org",
	projectId: "iamrex",
	storageBucket: "iamrex.appspot.com",
	messagingSenderId: "709549960746",
	appId: "1:709549960746:web:b351c3ae9bbe949da09d53",
	measurementId: "G-K7XMK1NXNT"
};

export default appConfig;