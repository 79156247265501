import Ripple from 'material-ripple-effects';

function Article({ title, cover, date, category, onClick }) {
	const ripple = new Ripple();
	return (
		<button onClick={onClick} onMouseUp={(e) => ripple.create(e, 'light')} className="flex items-start text-start place-items-center w-full mb-4">
			<img className="rounded-xl w-24 h-14 object-cover" src={cover} alt="Cover" />
			<div className="text-start ms-2 my-auto">
				<h4 className="text-sm font-bold flex justify-start">{title}</h4>
				<div className="inline-flex gap-x-3">
					<span className="text-xs text-gray-500 dark:text-gray-400">{date}</span>
					<span className="text-xs dark:text-white">&bull;</span>
					<span className="text-xs text-gray-500 dark:text-gray-400">{category}</span>
				</div>
			</div>
		</button>
	);
}

export default Article;