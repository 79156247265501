import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp';

import PageLayout from './../Component/PageLayout.js';
import Nav from './../Component/Widget/Nav.js';
import SearchField from './../Component/Widget/SearchField.js';
import ContentLayout from './../Component/ContentLayout.js';
import CenterLayout from './../Component/CenterLayout.js';
import TodayArticle from './../Component/Widget/TodayArticle.js';
import TodayArticleLoader from './../Component/Widget/TodayArticleLoader.js';
import Article from './../Component/Widget/Article.js';
import Tool from './../Component/Utility/Tool.js';
import appConfig from './../Server/Config.js';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, getDocs, getDoc, doc, query, limit, orderBy } from "firebase/firestore";

const app = initializeApp(appConfig);
const auth = getAuth();
const database = getFirestore(app);

function HomePage() {
	const navigate = useNavigate();
	const [article, setArticle] = useState(null);
	const [articleList, setArticleList] = useState(null);
	const [user, setUser] = useState(null);

	const style = {
		margin: 0,
		top: 'auto',
		right: 20,
		bottom: 20,
		left: 'auto',
		position: 'fixed',
		bgColor: '#000000'
	}

	document.title = 'IAMREX - Home';

	getDocs(query(collection(database, 'posts'), orderBy('created_at', 'desc'), limit(1)))
		.then((snapshot) => {
			snapshot.forEach((doc) => setArticle(doc.data()));
		});

	getDocs(query(collection(database, 'posts'), orderBy('created_at', 'desc'), limit(10)))
		.then((snapshot) => {
			const list = [];
			snapshot.forEach((doc) => {
				list.push(doc.data());
			});
			setArticleList([...list]);
		});

	onAuthStateChanged(auth, (user) => {
		if (user)
			getDoc(doc(database, 'users', auth.currentUser.uid))
				.then((snapshot) => setUser(snapshot.data()));
	});

	return (
		<PageLayout>
			<CenterLayout>
				<div className="w-full h-full sm:w-96">
					<Nav />
					<ContentLayout className="pb-4 relative">
						<SearchField />

						<div className="grid gap-x-0 gap-y-3">
							<h4 className="font-bold text-xl dark:text-white">Today's Article</h4>
							{!article ?
								(<TodayArticleLoader />)
								:
								(<TodayArticle
									title={article.title}
									cover={article.cover}
									date={Tool.formatDate(article.created_at)}
									timeToRead="3 min"
									category={Tool.getCategoryById(article.category)} />)
							}
						</div>

						<div className="my-3 flex justify-between">
							<h3 className="text-xl font-bold dark:text-white">More Articles</h3>
							<button type="button" className="text-xs text-gray-500 font-medium dark:text-gray-400">See all</button>
						</div>

						{
							articleList != null && articleList.map((art) =>
								<Article
									title={Tool.truncate(art.title, 24)}
									cover={art.cover}
									date={Tool.formatDate(art.created_at)}
									category={Tool.getCategoryById(art.category)} />)
						}

						{user && user.isAdmin &&
							<Fab onClick={() => { navigate('/compose/new') }} color="primary" sx={{ m: 10 }} style={style} aria-label="edit">
								<EditSharpIcon />
							</Fab>
						}
					</ContentLayout>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default HomePage;