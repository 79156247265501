import Box from '@mui/material/Box';

function ContentLayout({ children }) {
	return (
		<Box>
			{children}
		</Box>
	);
}

export default ContentLayout;