import { useNavigate } from "react-router-dom";
import { Button, Navbar, Typography } from "@material-tailwind/react";
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';

function NavBar({ title, action }) {
	const navigate = useNavigate();
	return (
		<Navbar className="fixed top-0 start-0 z-40 rounded-none shadow-none border-b border-b-gray-300 p-0 bg-white dark:bg-gray-900">
			<div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 px-3 pt-3.5 pb-2.5 sm:w-96">
				<div>
					<Button variant="text" size="sm" style={{ paddingLeft: '2px' }} onClick={(() => { navigate(-1) })}><ArrowBackIosNewSharpIcon fontSize="small" />Back</Button>
				</div>
				<div className="text-center flex items-center justify-center pb-1.5">
					<Typography variant="h6" className="text-black">{title}</Typography>
				</div>
				<div>
					{action ? action : (<span className="me-14"></span>)}
				</div>
			</div>
		</Navbar>
	);
}

export default NavBar;