import { Container } from '@mui/material';

function PageLayout({ children }) {
	return (
		<Container maxWidth="sm">
			{children}
		</Container>
	);
}

export default PageLayout;