import React from 'react';
import { Button } from "@material-tailwind/react";

class OutlinedButton extends React.Component {
	render() {
		return (
			<Button variant="outlined" className="rounded-full flex items-center justify-center" onClick={this.props.onClick} fullWidth>
				{this.props.label}
			</Button>
		);
	}
}

export default OutlinedButton;