import React from 'react';
import { useRouteError } from "react-router-dom";

import CenterLayout from './../Component/CenterLayout.js';
import ContentLayout from './../Component/ContentLayout.js';
import PageLayout from './../Component/PageLayout.js';
import logo from './../logo.png';

function ErrorPage() {
	const error = useRouteError();
	const { status, message } = error;
	document.title = status === 401 ? 'Unauthorized' : status === 403 ? 'Forbidden' : status === 404 ? 'Page Not Found' : status === 500 ? 'Internal Server Error' : 'Something went wrong';
	return (
		<PageLayout>
			<CenterLayout>
				<div className="dark:text-white w-full h-full sm:w-96">
					<ContentLayout>
						<img className="h-10 my-3" src={logo} alt="Logo" />
						<h3 className="text-xl font-bold">
							{status === 401 ? 'Unauthorized' : status === 403 ? 'Forbidden' : status === 404 ? 'Page Not Found' : status === 500 ? 'Internal Server Error' : 'Something went wrong'}
						</h3>
						<p className="text-sm">
							{status === 401 ? 'You aren\'t authorized to see this page.' : status === 403 ? 'You don\'t have permission to see this page.' : status === 404 ? 'The page you\'re looking for is not found on this server.' : status === 500 ? 'An error occured. Please try again.' : message}
						</p>
					</ContentLayout>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default ErrorPage;