import React from 'react';
import { Button as MTButton } from "@material-tailwind/react";

class Button extends React.Component {
	render() {
		return (
			<MTButton color="blue" onClick={this.props.onClick} className="rounded-full flex items-center justify-center" fullWidth>
				{this.props.label}
			</MTButton>
		);
	}
}

export default Button;