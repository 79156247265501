import { useState } from 'react';
import { Dialog, DialogContentText, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';

function AlertDialog({ show, title, message, onDismiss }) {
	const [status, setStatus] = useState(show);

	function closeDialog() {
		setStatus(false);
	}

	return (
		<Dialog
			open={status}
			onClose={onDismiss} fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={closeDialog}>Okay</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AlertDialog;