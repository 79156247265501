import React from 'react';
import { Spinner } from "@material-tailwind/react";

class ProgressLoader extends React.Component {
	render() {
		return (
			<Spinner color="white" size="sm" className="h-4 w-4 text-gray-200" />
		);
	}
}

export default ProgressLoader;