import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import appConfig from './Config.js';

const app = initializeApp(appConfig);
const messaging = getMessaging(app);
const auth = getAuth();
const database = getFirestore(app);

class Messaging {
	static getWebToken() {
		getToken(messaging, { vapidKey: "BOonxf80LFNfVmwOgggvPAdI3h_LJYgYq8B6_qFbPr-4fROFFHOynU3K8g_F5kkMbF6fNpoFN5MEx1V04FR1nFw" })
			.then((currentToken) => {
				if (currentToken) {
					onAuthStateChanged(auth, (user) => {
						if (user) {
							updateDoc(doc(database, 'users', user.uid), {
								tokens: {
									web: currentToken
								}
							});
						}
					});
				} else {
					this.requestPermission();
				}
			}).catch((err) => {
				console.error('FCM->getToken(): ', err);
			});
	}

	requestPermission() {
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				Messaging.getWebToken();
			}
		});
	}
}

export default Messaging;