import { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Input } from "@material-tailwind/react";

import Button from './../Component/Widget/Button.js';
import ProgressLoader from './../Component/Widget/ProgressLoader.js';
import OutlinedButton from './../Component/Widget/OutlinedButton.js';
import AlertDialog from './../Component/Modal/AlertDialog.js';
import CenterLayout from './../Component/CenterLayout.js';
import PageLayout from './../Component/PageLayout.js';
import logo from './../logo.png'
import 'animate.css';

import appConfig from './../Server/Config.js';

import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";

function SignupPage() {
	document.title = 'Signup - IAMREX';

	const [status, setStatus] = useState('typing');
	const [error, setError] = useState(null);
	const [nameValue, setNameValue] = useState('');
	const [emailValue, setEmailValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');

	const app = initializeApp(appConfig);
	const auth = getAuth();
	const database = getFirestore(app);

	const onTextNameChanged = (event) => {
		setNameValue(event.target.value);
	}

	const onTextEmailChanged = (event) => {
		setEmailValue(event.target.value);
	}

	const onTextPasswordChanged = (event) => {
		setPasswordValue(event.target.value);
	}

	const handleSignupClick = (event) => {
		doSignup()
			.then((result) => {
				setDoc(doc(database, 'users', result.uid), {
					uid: result.uid,
					name: result.displayName,
					email: result.email,
					membership: 'basic',
					membership_exp: Date.now() + 2592000,
					profile_photo: result.photoURL,
					isAdmin: false,
					created_at: Date.now()
				}).then(() => {
					sendEmailVerification(result)
						.then(() => {
							setStatus('success');
						});
				});
			})
			.catch((error) => setError(error));
	}

	function doSignup() {
		return new Promise((resolve, reject) => {
			if (emailValue.length < 6) {
				reject('Email address should be at least 6 characters and above.');
			} else if (passwordValue.length < 6) {
				reject('Password should be at least 6 characters and above.');
			} else if (nameValue.length < 6) {
				reject('Name should be at least 6 characters and above.');
			} else {
				setStatus('submitting');
				createUserWithEmailAndPassword(auth, emailValue, passwordValue)
					.then((result) => {
						const credential = result.user;
						updateProfile(result.user, {
							displayName: nameValue,
							photoURL: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
						}).then(() => {
							resolve(credential);
						}).catch((error) => {
							reject(error);
						});
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						switch (errorCode) {
							case 'auth/network-request-failed':
								reject('Network error.');
								break;

							case 'auth/invalid-credential':
								reject('Account does\'t exist.');
								break;

							default:
								reject('An error occured. Please try again.');
								break;
						}
						setStatus('error');
						console.log(errorMessage);
					});
			}
		});
	}

	return (
		<PageLayout>
			<CenterLayout>
				{
					status === 'success' && (<AlertDialog show={true} title="Verification link" message="Email verification link sent." onDismiss={(<Navigate to="/" replace={true} />)} />)
				}
				<div className="w-full h-full pt-14 sm:w-96">
					<div className="flex items-center justify-center mb-5">
						<img className="h-14" src={logo} alt="Logo" />
					</div>
					<div className="px-4 mb-5 sm:px-0">
						<Input type="text" onChange={onTextNameChanged} label="Name" size="lg" />
					</div>
					<div className="px-4 mb-5 sm:px-0">
						<Input type="email" onChange={onTextEmailChanged} label="Email address" size="lg" />
					</div>
					<div className="px-4 mb-3 sm:px-0">
						<Input type="password" onChange={onTextPasswordChanged} label="Password" size="lg" />
					</div>
					{error !== null &&
						<div className="px-4 mt-2 sm:px-0">
							<p className="text-xs text-red-600">
								{error}
							</p>
						</div>
					}
					<div className="grid gap-y-5 px-4 mt-5 mb-3 sm:px-0">
						<Button onClick={handleSignupClick} label={status === 'submitting' ? (<ProgressLoader />) : 'SIGN UP'}></Button>
						<Link to="/auth/login">
							<OutlinedButton label="BACK TO LOGIN"></OutlinedButton>
						</Link>
					</div>
				</div>
			</CenterLayout>
		</PageLayout>
	);
}

export default SignupPage;