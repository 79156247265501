import { useState } from 'react';
import { Navbar } from "@material-tailwind/react";
import { Link } from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import appConfig from './../../Server/Config.js';
import logo from './../../logo.png';
import 'animate.css';

import ProfilePhoto from './../../User/ProfilePhoto.js';

initializeApp(appConfig);
const auth = getAuth();

function Nav() {
	const [user, setUser] = useState(null);

	onAuthStateChanged(auth, (user) => {
		if (user) {
			setUser(user);
		}
	});

	function getMessage() {
		var ndate = new Date();
		var hours = ndate.getHours();
		var message = hours < 12 ? 'Good Morning!' : hours < 18 ? 'Good Afternoon!' : 'Good Evening!';
		return message;
	}

	return (
		<Navbar className="bg-white shadow-none p-0 dark:bg-gray-900">
			<div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 px-0 pt-3.5 pb-2">
				<Link to="/">
					{user ?
						(<div className="animate__animated animate__fadeIn grid gap-x-0 gap-y-0 text-black font-bold">
							<div className="text-xs">{user.displayName != null ? 'Hi ' + user.displayName + ',' : 'Hi User,'}</div>
							<div className="text-lg">{getMessage()}</div>
						</div>)
						:
						(<img className="h-8" src={logo} alt="Logo" />)
					}
				</Link>
				<Link to={user ? '/account/settings' : '/auth/login'}>
					<button type="button" className="focus:outline-none" aria-controls="navbar-default" aria-expanded="false">
						<span className="sr-only">User</span>
						<ProfilePhoto />
					</button>
				</Link>
			</div>
		</Navbar>
	);
}

export default Nav;